import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useGeneralStore = defineStore("general", () => {
  const _youbeFilter = ref({
    regione: "all",
    provincia: "all",
    citta: "all",
    agente: null,
  });

  const youbeFilter = computed(() => {
    return _youbeFilter.value;
  });

  function setYoubeFilter(filter) {
    _youbeFilter.value = filter;
  }

  function resetGeneralFilter() {
    _youbeFilter.value = {
      regione: "all",
      provincia: "all",
      citta: "all",
      agente: null,
    };
  }

  return {
    youbeFilter,
    setYoubeFilter,
    resetGeneralFilter,
  };
});
